<template>
    <component :is="sectionComponent" padding-top="10px" padding-bottom="10px">
        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            Vev_ Példánya <br />
            paysafecard fizetés<br />
            E.R.T. Hungary Kft.<br />
            Budapest 1145 Újvilág u. 50-52.<br /><br />

            Terminal id: {{ terminalId }}<br />
            Tranzakció id: {{ transactionId }}<br />
            Dátum: {{ date }} {{ time }}<br />
            paysafecard CLASSIC <br />
            Összeg: {{ value }}<br />
            Sorozatszám: {{ serial }}<br />
            PIN-CODE: {{ code }}<br /><br />

            FIGYELEM! <br />
            A PIN-Kód készpénz is egyben, ezért tilos telefonon keresztül bárkinek is megadni!<br />
            A paysafecard kupon kizárólag a paysafecard szerz_déses partnereinél használható fel online fizetési
            eszközként.<br />
            A paysafecard kupont soha nem szabad idegeneknek átadni, vagy a paysafecard által elfogadott szerz_déses
            partnereken kívül más kifizetésekre használni.<br />
            A PIN-kód megadására vonatkozó telefonos vagy e-mailes felszólításnak soha nem szabad eleget tenni.<br /><br />

            Help: <a target="_blank" href="https://www.paysafecard.com/help">www.paysafecard.com/help</a> <br />
            HÉ-PÉ: 09:30 - 15:30 óráig<br />
            E-Mail: info.hu@paysafecard.com<br />
            A paysafecard kereskedelemben történ_ továbbértékesítése tilos.<br />
            Az általános üzleti feltételek a www.paysafecard.com weboldalon találhatók.<br />
            A paysafecard fizet_ eszköz, melynek kibocsájtója <strong>Paysafe Prepaid Services Limited</strong><br />
        </component>
    </component>
</template>

<script>
export default {
    props: ['isEmail', 'value', 'code', 'serial', 'terminalId', 'traceCode', 'date', 'time', 'transactionId'],

    computed: {
        sectionComponent() {
            return this.isEmail ? 'mj-section' : 'div';
        },

        textComponent() {
            return this.isEmail ? 'mj-text' : 'div';
        },

        hrComponent() {
            return this.isEmail ? 'mj-divider' : 'hr';
        },
    },
};
</script>
